import { Flex } from "@appsmith/wds";
import type { ForwardedRef } from "react";
import React, { forwardRef, useLayoutEffect, useState } from "react";

import styles from "./styles.module.css";
import { ChatHeader } from "./ChatHeader";
import type { AIChatProps } from "./types";
import { ChatMessageList } from "./ChatMessageList";
import { ChatInputSection } from "./ChatInputSection";

const _AIChat = (props: AIChatProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    chatDescription,
    chatTitle,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    thread,
    threadId,
    ...rest
  } = props;
  const [canvasHeight, setCanvasHeight] = useState(0);

  useLayoutEffect(() => {
    const root = document.querySelector(".canvas");

    if (root) {
      setCanvasHeight(root.clientHeight);
    }
  }, []);

  const isDeleteThreadDisabled =
    !threadId || isThreadLoading || isWaitingForResponse;

  return (
    <Flex
      className={styles.aiChat}
      direction="column"
      ref={ref}
      {...rest}
      minHeight={{
        base: "var(--max-height)",
        "768px": "700px",
        "1200px": "900px",
      }}
      style={
        {
          "--canvas-height": `${canvasHeight}px`,
        } as React.CSSProperties
      }
    >
      <ChatHeader
        description={chatDescription}
        isDeleteThreadDisabled={isDeleteThreadDisabled}
        onDeleteThread={onDeleteThread}
        title={chatTitle}
      />

      <ChatMessageList
        isThreadLoading={isThreadLoading}
        onApplyAssistantSuggestion={onApplyAssistantSuggestion}
        thread={thread}
      />

      <ChatInputSection
        isThreadLoading={isThreadLoading}
        isWaitingForResponse={isWaitingForResponse}
        onAttachFiles={onAttachFiles}
        onPromptChange={onPromptChange}
        onSubmit={onSubmit}
        prompt={prompt}
        promptInputPlaceholder={promptInputPlaceholder}
      />
    </Flex>
  );
};

export const AIChat = forwardRef(_AIChat);

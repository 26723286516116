import React, { useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  Markdown,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
} from "@appsmith/wds";

import styles from "./styles.module.css";

interface ChatHeaderProps {
  title?: string;
  description?: string;
  onDeleteThread?: () => void;
  isDeleteThreadDisabled?: boolean;
}

export const ChatHeader = (props: ChatHeaderProps) => {
  const { description, isDeleteThreadDisabled, onDeleteThread, title } = props;
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);

  return (
    <>
      <Flex alignItems="center" className={styles.header} gap="spacing-3">
        <Text fontWeight={600} lineClamp={1} size="subtitle">
          {title}
        </Text>
        {description && (
          <Button
            icon="info-square-rounded"
            onPress={() => setIsDescriptionModalOpen(true)}
            variant="ghost"
          />
        )}
        {onDeleteThread && (
          <IconButton
            className={styles.deleteButton}
            color="neutral"
            icon="trash"
            isDisabled={isDeleteThreadDisabled}
            onPress={onDeleteThread}
            variant="ghost"
          />
        )}
      </Flex>

      <Modal
        isOpen={isDescriptionModalOpen}
        setOpen={setIsDescriptionModalOpen}
        size="small"
      >
        <ModalContent>
          <ModalHeader title="Information about the bot" />
          <ModalBody>
            {description && <Markdown>{description}</Markdown>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
